@import 'src/app/css-variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: $spacing-large;
  padding: 40px $spacing-large;

  border-radius: $radius-small;
  border: 1px solid $border-color;

  @media all and (max-width: $breakpoint-small) {
    flex-direction: column;
    justify-content: center;

    gap: 40px;
    padding: $spacing-normal;
  }

  &:hover {
    background: $card-background;
    border-color: $highlight-color;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: $spacing-normal;

  .header {
    display: flex;
    flex-direction: column;
    gap: $spacing-extra-small;

    h4 {
      color: $secondary-color;
    }
  }

  @media all and (max-width: $breakpoint-small) {
    width: 100%;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  gap: $spacing-normal;
  align-items: center;
  justify-content: space-between;

  @media all and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media all and (max-width: $breakpoint-small) {
    gap: $spacing-small;
    flex-direction: column;
  }

  .linksWrapper {
    display: flex;
    flex-direction: row;
    gap: $spacing-small;
    align-items: center;

    a {
      color: $title-text;
      text-decoration: none;
      //font-size: $font-size-medium;
    }

    .sectionTitle {
      font-weight: 700;
      color: $accent-text;

      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: $font-size-small;
    }

    .socialIcon {
      font-size: $font-size-medium;
    }
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;
  width: 200px;

  img {
    max-height: 60px;
    max-width: 100%;
  }
}
