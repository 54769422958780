@import 'css-variables';

@mixin max-width-container {
  box-sizing: border-box;

  padding-left: var(--padding-horizontal);
  padding-right: var(--padding-horizontal);

  margin: auto;
  width: 1700px;
  max-width: 100%;

  @media all and (max-width: 1700px) {
    width: 100%;
  }
}
