@import 'src/app/css-variables';

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableRow {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(var(--table-number-of-columns), 1fr);
  width: 100%;
  border-bottom: 1px solid $border-color;
}

.mobileRow {
  display: grid;
  align-items: center;
  grid-template-rows: 1fr;
  width: 100%;
  border-bottom: 1px solid $border-color;
}

.mobileCell {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: $spacing-large;
  gap: $spacing-large;

  .cell-value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.emptySection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 60px;
  text-transform: uppercase;

  width: 100%;
  height: 144px;

  opacity: 0.3;
  border: 1px dashed #9b9b9b;
  border-radius: 5px;
}

.headerCell {
  padding: 0px 0px 20px;
  color: #a7a7a7;
}

.cell {
  flex-direction: column;
  display: flex;
  padding-block: $spacing-large;
}
