@import 'src/app/css-variables';

.itemContainer {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-end;

  scale: 1;
  cursor: pointer;
  aspect-ratio: 2 / 3;

  gap: $spacing-medium;
  padding: $spacing-medium;
  border-radius: $radius-small;
  border: 1px solid $border-color;

  background-size: cover;
  background-position: right center;
  background-blend-mode: luminosity;
  background-color: $card-background;

  transition: opacity 500ms linear, transform 300ms linear;

  &:hover {
    transform: scale(1.01);
    background-color: $body-color;
    border-color: $highlight-color;
    background-blend-mode: normal;

    .icon {
      display: block;
      animation: move 2s linear infinite;
    }
  }

  &::before {
    top: 0;
    left: 0;
    content: '';

    z-index: -1;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;

    background: linear-gradient(
        0deg,
        transparentize($body-color, 0.1),
        0%,
        transparentize($body-color, 0.2) 5%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(
        40deg,
        transparentize($body-color, 0.3),
        0%,
        transparentize($body-color, 0.5) 10%,
        rgba(0, 0, 0, 0) 100%
      );
    background-color: transparentize($body-color, 0.7);
  }

  @media all and (max-width: $breakpoint-large) {
    gap: $spacing-normal;
    padding: $spacing-normal;
    font-size: $font-size-small;
  }

  @media all and (max-width: $breakpoint-small) {
    aspect-ratio: 2 / 1;
  }
}

.contentContainer {
  flex: 1;
  display: flex;
  gap: $spacing-small;
  align-items: center;
  justify-content: space-between;

  .comingSoonText {
    letter-spacing: 2px;
    text-transform: uppercase;

    font-weight: 600;
    color: desaturate($secondary-color, 40);
    font-size: $font-size-small;
  }

  .icon {
    display: none;
    font-size: 1.8em;
    position: relative;
  }
}

@keyframes move {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}
