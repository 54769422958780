@import 'src/app/css-variables';

.box {
  display: flex;
  flex-direction: column;
  padding: $spacing-extra-large;
  border-radius: 10px;
  position: relative;

  border: 1px solid $border-color;
  background-color: $body-color;

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //transform: translate3d(0px, 20px, 0) scale(0.95);
    opacity: 0.3;
    filter: blur(60px);
    transition: opacity 0.3s;
    border-radius: inherit;
  }

  /*
    * Prevents issues when the parent creates a
    * stacking context. (For example, using the transform
    * property )
    */
  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
  }
}
