@import 'src/app/css-variables';

.section {
  display: flex;
  flex-direction: column;
  gap: $spacing-medium;
  height: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  padding: $spacing-large;
  gap: $spacing-large;
  background: $surface-color;
  border: 1px solid $border-color;
  border-radius: 10px;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: row;
  gap: $spacing-large;
  align-items: center;

  > div {
    font-weight: 700;
    text-transform: uppercase;
  }
}
