@import 'src/app/css-variables';

.container {
  display: flex;
  align-items: center;
  gap: $spacing-large;
  padding: $spacing-large;
  justify-content: space-between;

  border-radius: $radius-small;

  .content {
    display: flex;
    flex-direction: column;
    gap: $spacing-extra-small;
    max-width: $breakpoint-extra-small;
  }

  @media all and (max-width: $breakpoint-large) {
    padding: $spacing-normal;
  }

  @media all and (max-width: $breakpoint-small) {
    padding: 0;
  }
}

.linksWrapper {
  display: flex;
  gap: $spacing-small;
  padding-top: $spacing-normal;

  .link {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    aspect-ratio: 1 / 1;

    font-size: 2em;
    color: $title-text;
    border-radius: $radius-small;
    border: 1px solid $border-color;
  }
}

.illustrationContainer {
  display: flex;

  height: 350px;
  aspect-ratio: 746 / 638;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;

  @media all and (max-width: $breakpoint-medium) {
    height: 250px;
  }

  @media all and (max-width: $breakpoint-small) {
    height: 180px;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    display: none;
  }
}
