@import 'src/app/css-variables';

.container {
  display: flex;
  width: max-content;
  flex-direction: row;

  padding: $spacing-extra-small;

  border-radius: $radius-small;
  border: 1px solid $accent-text;

  &[data-disabled='true'] {
    opacity: 0.7;
    pointer-events: none;
  }

  .option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    cursor: pointer;
    font-weight: 900;

    user-select: none;
    color: $accent-text;
    border-radius: $radius-extra-small;

    padding: $spacing-extra-small $spacing-normal;

    &[data-active='true'] {
      color: $text-color;
      background: $primary-color;
    }
  }
}
