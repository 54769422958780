@import 'src/app/css-variables';
@import 'src/app/theme/typography';

.sectionContainer {
  display: flex;
  flex-direction: column;

  gap: $spacing-normal;

  .titleWrapper {
    display: flex;
    flex-direction: column;

    gap: $spacing-small;
    max-width: $breakpoint-small;
  }
}

.itemsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  gap: $spacing-small;

  &:has(> *:hover) {
    > *:not(:hover) {
      opacity: 0.3;
    }
  }

  @media all and (max-width: $breakpoint-large) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (max-width: $breakpoint-extra-small) {
    grid-template-columns: 1fr;
  }
}
