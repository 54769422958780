@import 'src/app/css-variables';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: $spacing-medium;
}

.itemsWrapper {
  display: flex;
  flex-direction: column;

  gap: $spacing-small;
}
