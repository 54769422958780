@import 'src/app/css-variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin-block-end: 0;
  margin-block-start: 0;

  line-height: 1.2;
  color: $title-text;
}

h1 {
  font-size: 4.5em;

  @media all and (max-width: $breakpoint-small) {
    font-size: 3.2em;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    font-size: 2.5em;
  }
}

h2 {
  font-size: 3em;

  @media all and (max-width: $breakpoint-large) {
    font-size: 2.5em;
  }

  @media all and (max-width: $breakpoint-small) {
    font-size: 2.2em;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    font-size: 2em;
  }
}

h3 {
  font-size: 2.2em;

  @media all and (max-width: $breakpoint-small) {
    font-size: 2em;
  }

  @media all and (max-width: $breakpoint-small) {
    font-size: 1.8em;
  }
}

h4 {
  font-size: 1.5em;
}

.multiversX {
  font-weight: 900;
  color: $title-text;
}
