@import 'src/app/css-variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 300px;
  text-align: center;

  padding: $spacing-normal $spacing-medium;
  border-radius: $radius-small;
  background: $card-background;
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-small;

  font-size: $font-size-medium;
  max-width: $breakpoint-medium;

  span {
    color: $primary-color;
  }
}
