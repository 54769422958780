/** Colors **/
$primary-color: #367cff;
$secondary-color: #e1a200;

$text-color: #c4c4c4;
$title-text: #ececec;
//$copy-text-color: #a1a4a4;
$title-text-color: #e7e9ea;
$text-color-on-primary: $title-text-color;
$overlay-color: rgba(255, 255, 255, 0.15);

$text-color-secondary: #ffb800;
$text-color-on-secondary: #d5d5d5;

$body-color: #070707;
//$body-color: #111315;

$surface-color: $body-color;
//$surface-color: #1a1d1e;
//$surface-text-color: $text-color;

$button-disabled-color: #4f4e4e;

$accent-color: #1c1d21;
$accent-text: #848484;

// $border-color: #2D2E33;
$highlight-color: #4f4e4e;

$card-background: #141519;

$error-color: #fd6153;
$success-color: #2e9453;
$warning-color: #ffd987;
$disabled-color: #6b6b6b;

/** Borders **/
$border-color: #39404a;

/** Spacing **/
$spacing-extra-small: 8px;
$spacing-small: 16px;
$spacing-normal: 24px;
$spacing-medium: 32px;
$spacing-large: 64px;
$spacing-extra-large: 128px;

/** Radius **/
$radius-extra-small: 4px;
$radius-small: 8px;
$radius-normal: 12px;
$radius-medium: 16px;
$radius-large: 32px;
$radius-extra-large: 64px;

/** Breakpoints **/
$breakpoint-extra-small: 576px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1200px;
$breakpoint-extra-large: 1400px;

/* Font family, sizes and weights */
$main-font-family: Montserrat, sans-serif;

$font-size-extra-small: 8px;
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-medium: 20px;
$font-size-large: 32px;
$font-size-extra-large: 64px;

/** COMPONENTS **/
$header-size: 80px;
$max-content-width: 1168px;

/** Input **/
$input-height: 40px;
$input-background: #0c1015;
