@import 'src/app/css-variables';

.form {
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: $spacing-small;
}

.modalContent {
  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
  gap: $spacing-medium;
  padding-block: $spacing-small;
  padding-inline: $spacing-large;

  @media all and (max-width: $breakpoint-small) {
    padding: $spacing-small;
  }

  max-width: $breakpoint-small;

  --color: #{$success-color};
  &[data-result-type='fail'] {
    --color: #{$error-color};
  }

  .title {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $spacing-small;

    font-weight: 900;
    letter-spacing: 1px;

    &,
    h4 {
      color: var(--color);
    }

    :not(h4) {
      font-size: $font-size-large;
    }
  }

  .textContent {
    display: flex;
    flex-direction: column;

    gap: $spacing-small;

    .verificationResult {
      strong {
        color: var(--color);
      }
    }
  }
}
