@import 'src/app/css-variables';

.container {
  display: flex;
  flex-direction: row;
  padding: 5px;

  border: 1px solid $border-color;
  border-radius: $radius-extra-small;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  cursor: pointer;
  padding: 10px 30px;
  border-radius: inherit;

  &[data-active='true'] {
    color: $title-text;
    background-color: $accent-color;
  }

  font-weight: 700;

  @media all and (max-width: $breakpoint-small) {
    font-size: $font-size-small;
  }
}
