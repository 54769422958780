@import 'src/app/css-variables';
@import 'src/app/mixins';

.componentWrapper {
  flex: 1;
  display: flex;
  position: relative;

  isolation: isolate;

  flex-direction: column;
  padding: $spacing-extra-large 0;
}

.componentContent {
  @include max-width-container;

  .limitedContent {
    display: flex;
    align-self: center;
    flex-direction: column;

    width: 900px;
    max-width: 100%;
    gap: $spacing-medium;

    @media all and (max-width: $breakpoint-large) {
      width: auto;
      max-width: initial;
    }

    @media all and (max-width: $breakpoint-medium) {
      align-self: flex-start;
    }

    @media all and (max-width: $breakpoint-extra-small) {
      max-width: initial;
    }
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-medium;

  @media all and (max-width: $breakpoint-extra-small) {
    width: auto;
    flex-direction: column;
  }
}

.imageContainer {
  bottom: 0;
  right: 0;

  z-index: -1;
  width: 100%;
  isolation: isolate;
  position: absolute;

  img {
    width: 100%;
    max-height: calc(100vh - 100px);
  }

  @media all and (max-width: $breakpoint-small) {
    right: -20px;
  }

  &::after {
    top: 0;
    left: 0;
    content: '';
    display: block;
    position: absolute;

    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, $body-color, 0%, $body-color 5%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(40deg, $body-color, 0%, $body-color 30%, rgba(0, 0, 0, 0) 100%);
  }
}
