@import 'src/app/css-variables';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: $spacing-small;

  padding: $spacing-normal $spacing-medium;
  background-color: $card-background;

  border: 1px solid $border-color;
  border-radius: $radius-extra-small;

  @media all and (max-width: $breakpoint-medium) {
    align-items: initial;
    flex-direction: column;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    gap: $spacing-normal;
    padding: $spacing-normal;
  }
}

.netWorthContainer {
  flex: 1;

  .title {
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: $title-text;
  }

  line-break: strict;
  word-break: keep-all;

  .egldValue {
    font-weight: 900;
    color: $primary-color;
    font-size: $font-size-large;
  }

  .usdValue {
    font-weight: 700;
    color: $secondary-color;
    font-size: $font-size-medium;
  }
}

.itemsWrapper {
  flex: 1;
  display: flex;
  gap: $spacing-normal;

  border-radius: inherit;
  padding: $spacing-normal;
  background-color: $accent-color;

  @media all and (max-width: $breakpoint-large) {
    flex: none;
    flex-grow: 1;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    padding: 0;
    gap: $spacing-extra-small;

    background: none;
    flex-direction: column;
  }
}

.item {
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: $spacing-extra-small;
  border-right: 1px solid $border-color;

  &:last-child {
    border-right: none;
  }

  .title {
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: $font-size-small;

    color: $secondary-color;
  }

  .egldValue {
    font-weight: 900;
    color: $title-text;
  }

  .usdValue {
    color: $accent-text;
    font-size: $font-size-small;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    border-radius: inherit;
    padding: $spacing-small;
    background-color: $accent-color;
  }
}
