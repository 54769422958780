@import 'src/app/theme/typography';

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .subTitle {
    @include page-subtitle-mixin;
  }
}
