@import 'src/app/css-variables';

.container {
  display: flex;
  flex-direction: column;

  gap: $spacing-normal;
  padding: $spacing-normal;

  background: $card-background;

  border-radius: $radius-small;
  border: 1px solid $border-color;
}

.itemsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  gap: $spacing-small;
}

.itemContainer {
  gap: $spacing-small;

  display: flex;
  flex-direction: column;
  padding: 30px 30px 20px;

  border-radius: 10px;

  @media all and (max-width: $breakpoint-extra-small) {
    width: 100%;
  }
}

.participantRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: 700;
  }
}
