@import 'src/app/mixins';
@import 'src/app/theme/typography';

.screenContainer {
  display: flex;
  flex-direction: column;

  gap: $spacing-large;
  padding-block: $spacing-extra-large;

  @media all and (max-width: $breakpoint-medium) {
    padding-top: $spacing-extra-large * 1.5;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    padding-top: $spacing-extra-large;
  }
}

.sectionMaxWidthWrapper {
  @include max-width-container;
}

.sectionsMaxWidthWrapper {
  @include max-width-container;

  display: flex;
  flex-direction: column;

  gap: $spacing-large;
}
