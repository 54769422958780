@import 'src/app/css-variables';
@import 'src/app/theme/typography';

.sectionContainer {
  display: flex;
  flex-direction: column;

  gap: $spacing-medium;

  .titleWrapper {
    display: flex;
    flex-direction: column;

    gap: $spacing-small;
    max-width: $breakpoint-small;
  }

  .sectionTitle {
    @include section-title-mixin;
  }

  .sectionParagraph {
    @include section-paragraph-mixin;
  }
}

.teamMembersWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  gap: $spacing-medium;

  @media all and (max-width: $breakpoint-extra-large) {
    gap: $spacing-small;
  }

  @media all and (max-width: $breakpoint-large) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media all and (max-width: $breakpoint-medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (max-width: $breakpoint-small) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.teamMember {
  display: flex;
  flex-direction: column;

  gap: $spacing-small;
  padding: $spacing-medium;

  border: 1px solid $border-color;
  border-radius: $radius-extra-small;

  &:hover {
    background-color: $card-background;

    .avatar {
      filter: none;
    }
  }

  .avatar {
    width: 100px;
    aspect-ratio: 1 / 1;
    border-radius: 100%;

    filter: grayscale(1);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .nameAndTitle {
    gap: 5px;
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 700;
      color: $title-text;
      font-size: $font-size-medium;
    }

    .title {
      color: desaturate($secondary-color, 50);
    }
  }

  .description {
    flex-grow: 1;
    line-height: 1.35;
  }

  .socialLinksWrapper {
    display: flex;
    gap: $spacing-small;
    font-size: $font-size-medium;

    &,
    a {
      color: $text-color;
    }
  }
}
