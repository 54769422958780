@import 'src/app/css-variables';

.paragraphsWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-small;
}

.sectionContainer {
  display: flex;
  align-items: center;
  gap: $spacing-extra-large * 2;

  @media all and (max-width: $breakpoint-extra-large) {
    gap: $spacing-extra-large;
  }

  @media all and (max-width: $breakpoint-medium) {
    gap: $spacing-large;
    flex-direction: column;

    &[data-reversed='true'] {
      flex-direction: column-reverse;
    }
  }
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;

  gap: $spacing-small;

  .buttonsContainer {
    display: flex;
    align-items: flex-start;
  }
}
