@import 'src/app/css-variables';

.container {
  display: flex;
  flex-direction: column;

  gap: $spacing-normal;
}

.titleWrapper {
  display: flex;
  flex-direction: column;

  gap: $spacing-small;
  max-width: $breakpoint-medium;
}

.itemsWrapper {
  display: flex;
  gap: $spacing-normal;

  @media all and (max-width: $breakpoint-small) {
    flex-direction: column;
  }

  &:has(> *:hover) {
    > *:not(:hover) {
      opacity: 0.3;
    }
  }
}
