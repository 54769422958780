@import 'src/app/css-variables';

@mixin text-mixin {
}

@mixin page-title-mixin {
  color: $title-text;
}

@mixin page-subtitle-mixin {
  color: $title-text;
  font-size: $font-size-medium;
}

@mixin section-title-mixin {
  @include text-mixin;
  color: $title-text;
}

@mixin section-subtitle-mixin {
  @include text-mixin;
}

@mixin section-paragraph-mixin {
  @include text-mixin;
  font-size: 1.2em;
  line-height: 1.3;
}
