@import 'src/app/css-variables';

.sectionContainer {
  display: flex;
  flex-direction: column;

  gap: $spacing-normal;

  .titleWrapper {
    display: flex;
    flex-direction: column;

    gap: $spacing-small;
    max-width: $breakpoint-medium;
  }
}

.itemsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media all and (max-width: $breakpoint-small) {
    grid-template-columns: 1fr;
  }

  gap: $spacing-small;

  &:has(.itemContainer:hover) {
    .itemContainer:not(:hover) {
      opacity: 0.3;
    }
  }
}

.itemContainer {
  display: flex;
  gap: $spacing-medium;
  padding: $spacing-normal;

  border-radius: $radius-small;
  border: 1px solid $border-color;

  scale: 1;
  transition: opacity 500ms linear, transform 300ms linear;

  &:hover {
    transform: scale(1.01);
    background: $card-background;
    border-color: $highlight-color;
  }

  .itemIcon {
    width: 100px;
    aspect-ratio: 1 / 1;
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;

    @media all and (max-width: $breakpoint-medium) {
      width: 80px;
    }

    @media all and (max-width: $breakpoint-small) {
      width: 60px;
    }
  }

  .contentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $spacing-small;

    .paragraphsContainer {
      flex: 1;
      //display: flex;
    }

    .itemLink {
      &[data-disabled='true'] {
        color: $disabled-color;
      }
    }
  }

  @media all and (max-width: $breakpoint-small) {
    gap: $spacing-normal;
    flex-direction: column;
  }
}
