@import 'src/app/theme/typography';
@import 'src/app/mixins';

.heroContentContainer {
  display: flex;
  flex-direction: column;

  gap: inherit;
}

.heroTitle {
  @include page-title-mixin;
  font-weight: 900;
}

.heroSubtitle {
  @include page-title-mixin;
  font-weight: 600;
}

.heroParagraphsWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-small;
}

.heroParagraph {
  @include section-paragraph-mixin;
  font-size: 1.35em;
  line-height: 1.35;
}
