@import 'src/app/css-variables';

.section {
  display: flex;
  flex-direction: column;
  gap: $spacing-medium;
  height: 100%;
}

.tokens {
  --number-of-columns: 2;

  // TODO: Ask why this was added since it was not found in styled components
  @media all and (min-width: 1800px) {
    --number-of-columns: 2;
  }

  @media all and (max-width: $breakpoint-medium) {
    --number-of-columns: 2;
  }

  @media all and (max-width: $breakpoint-small) {
    --number-of-columns: 1;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    --number-of-columns: 1;
  }

  display: grid;
  grid-template-columns: repeat(var(--number-of-columns), 1fr);
  row-gap: $spacing-extra-large;
  column-gap: $spacing-extra-large;
}

.card {
  display: flex;
  flex-direction: column;
  padding: $spacing-large;
  gap: $spacing-large;
  background: $surface-color;
  border: 1px solid $border-color;
  border-radius: 10px;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: column;

  .name {
    font-weight: 700;
  }
}
