@import 'src/app/css-variables';
@import 'src/app/mixins';

$avatar-size: 150px;

.container {
  display: flex;
  position: relative;
  flex-direction: column;

  color: $accent-text;

  @media all and (max-width: $breakpoint-extra-small) {
    flex-direction: column;
  }
}

.cover {
  min-height: 300px;
  position: relative;
  padding: $spacing-normal;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $accent-color;
  border: 1px solid darken($border-color, 10);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      0deg,
      transparentize($body-color, 0) 0%,
      transparentize($body-color, 0) 5%,
      transparentize($body-color, 0.05) 10%,
      transparent 50%,
      transparent 100%
    );
    background-color: transparentize($accent-color, 0.4);
  }
}

.content {
  @include max-width-container;

  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  gap: $spacing-normal;
  margin-top: calc(0px - #{$avatar-size / 2});

  .avatar {
    display: flex;
    width: $avatar-size;
    aspect-ratio: 1 / 1;

    border-radius: $radius-small;
    border: 1px solid $border-color;

    background-size: cover;
    background-position: center;
    background-color: $accent-color;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding-block: $spacing-small;

    color: $title-text;

    .heroTag {
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;

  gap: $spacing-normal;
}
