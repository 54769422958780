@import 'src/app/css-variables';

.container {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-medium;

  @media all and (max-width: $breakpoint-medium) {
    gap: $spacing-small;
  }
}

.box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: $spacing-medium;
  padding: $spacing-medium;

  background: $card-background;
  border-radius: $radius-small;
  border: 1px solid $border-color;

  h4 {
    font-weight: 900;
  }

  .amountContainer {
    width: 100%;
    display: flex;
    gap: $spacing-small;
    justify-content: space-between;

    font-weight: 900;
    font-size: $font-size-large;
    line-height: $font-size-large;

    .amountValue {
      span {
        color: $accent-text;
        font-size: $font-size-medium;
      }
    }
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
