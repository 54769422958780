@import 'src/app/css-variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: $spacing-small;
  padding: $spacing-normal;

  width: 100%;
  background: $card-background;

  border-radius: $radius-small;
  border: 1px solid $border-color;
}

.itemsWrapper {
  display: flex;
  flex-direction: column;

  gap: 10px;
  width: 100%;

  @media all and (max-width: $breakpoint-extra-small) {
    gap: $spacing-normal;
  }
}

.itemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0;
  width: 100%;
  gap: $spacing-large;

  @media all and (max-width: $breakpoint-small) {
    gap: $spacing-small;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    align-items: initial;
    flex-direction: column;
    gap: $spacing-extra-small;
  }
}
