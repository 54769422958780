@import 'src/app/css-variables';

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: $spacing-extra-large;
  padding: $spacing-extra-large $spacing-extra-large;

  background-color: $surface-color;
  border-radius: var(--default-border-radius);

  svg {
    width: 256px;
    height: 256px;
    max-width: 80%;
    max-height: 256px;
  }

  @media all and (max-width: $breakpoint-medium) {
    gap: $spacing-extra-large;
    padding: $spacing-extra-large;
  }

  @media all and (max-width: $breakpoint-small) {
    padding: $spacing-extra-large;
    gap: $spacing-extra-large;

    svg {
      width: 192px;
      height: 192px;
      max-width: 80%;
      max-height: 192px;
    }
  }

  @media all and (max-width: $breakpoint-extra-small) {
    flex-direction: column-reverse;
  }
}

.wrapper {
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-extra-large;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: $spacing-medium;
}
