@import 'src/app/css-variables';

.wrapper {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  gap: $spacing-extra-large;
}

.container {
  display: flex;
  height: 100%;

  flex-direction: row;
  column-gap: $spacing-extra-large;
  justify-content: space-between;
  align-items: flex-start;

  @media all and (max-width: $breakpoint-medium) {
    flex-direction: row;
    justify-content: center;
  }

  @media all and (max-width: $breakpoint-small) {
    row-gap: $spacing-extra-large;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    row-gap: $spacing-large;
  }
}

.image {
  display: flex;
  flex-grow: 1;
  height: 100%;
  max-height: 800px;
  min-height: 650px;
  opacity: 1;

  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;

  @media all and (max-width: $breakpoint-large) {
    min-height: 500px;
  }

  @media all and (max-width: $breakpoint-small) {
    background-image: none;
    min-height: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: $spacing-extra-large;
  align-self: center;

  max-width: 800px;

  @media all and (max-width: $breakpoint-medium) {
    align-self: flex-start;
  }

  @media all and (max-width: $breakpoint-small) {
    align-self: flex-start;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    max-width: initial;
  }
}
