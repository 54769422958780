@import 'src/app/css-variables';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  > div {
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
}

.selectContainer {
  background-color: $body-color;
  border-top-left-radius: var(--border-top-left-radius);
  border-top-right-radius: var(--border-top-right-radius);
  border-bottom-left-radius: var(--border-bottom-left-radius);
  border-bottom-right-radius: var(--border-bottom-right-radius);
}

.controlContainer {
  &[data-is-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  > div {
    box-shadow: none;
    border-radius: $spacing-extra-small;
    border: 1px solid $border-color;
    background-color: $body-color;
    font-size: 0.85em;
    font-family: inherit;

    border-top-left-radius: var(--border-top-left-radius);
    border-top-right-radius: var(--border-top-right-radius);
    border-bottom-left-radius: var(--border-bottom-left-radius);
    border-bottom-right-radius: var(--border-bottom-right-radius);
  }

  &[data-is-focused='false'] > div {
    &:hover {
      border-color: $highlight-color;
    }
  }

  &[data-is-focused='true'] > div {
    border-color: $primary-color;
  }

  &[data-is-error='true'] > div {
    border-color: $error-color;
  }
}

.labelText {
  font-weight: 500;
}

.textComponents.disabled {
  opacity: 0.5;
}

.controlText {
  display: inline-flex;
  flex-grow: 1;
}

.placeholderText {
  opacity: 0.5;
  font-size: inherit;
}

.valueText {
  display: inline;
  grid-area: 1 / 1 / 2 / 3;
  margin-left: 2px;
  margin-right: 2px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.absoluteContainer {
  position: absolute;
  left: 10px;
  z-index: 0;
  display: grid;
}

.errorContainer {
  display: flex;
  padding-left: 15px;

  > div {
    line-height: normal;
    color: $error-color;
  }
}
