@import 'src/app/css-variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 40px;

  background: $card-background;
  border-radius: $radius-small;
  border: 1px solid $border-color;
}

.tokenWrapper {
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
