@import 'src/app/css-variables';

.listContainer {
  display: grid;
  grid-row-gap: $spacing-extra-small;
}

.listItemsWrapper {
  display: grid;
  grid-row-gap: $spacing-extra-small;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 20px;

  &.circle {
    list-style-type: circle;
  }

  &.decimal {
    list-style-type: decimal;
  }
}

.customListItemWrapper {
  list-style-type: decimal;
}

.customListItemContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: $spacing-extra-small;
}
