@import 'src/app/css-variables';

.container {
  display: grid;
  grid-row-gap: $spacing-extra-small;
}

.table {
  width: auto;
  border-collapse: collapse;
}

.cell {
  border: 1px solid $border-color;
  padding: $spacing-extra-small;
}

.headerCell {
  background-color: $primary-color;
  border: none;
  font-weight: 600;
}
