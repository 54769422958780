@import 'src/app/css-variables';

.wrapper {
  display: flex;
  flex-direction: column;

  position: fixed;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;

  z-index: 10;
  width: 100%;
  height: 100vh;

  background: $overlay-color;
}

.container {
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  background: $body-color;

  gap: $spacing-medium;
  padding: $spacing-medium;

  max-width: 700px;
  min-width: 500px;

  .topBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-weight: bold;
    color: $title-text;

    .closeButtonContainer {
      cursor: pointer;
    }
  }

  @media all and (max-width: $breakpoint-small) {
    min-width: initial;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.content {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
