@import 'src/app/css-variables';
@import 'src/app/mixins';

.componentWrapper {
  flex: 1;
  display: flex;
  position: relative;

  flex-direction: column;
  padding-block: $spacing-extra-large;

  @media all and (max-width: $breakpoint-small) {
    padding-block: $spacing-large;
  }
}

.componentContent {
  @include max-width-container;

  display: flex;
  flex-direction: column;
  gap: $spacing-medium;
}

.header {
  display: flex;
  flex-direction: column;

  gap: $spacing-small;

  .titlesWrapper h1 {
    font-weight: 900;
  }

  .websiteUrl {
    font-weight: 600;
    color: $primary-color;
  }
}

.body {
  display: flex;
  flex-direction: column;

  gap: $spacing-medium;
  max-width: $breakpoint-medium;

  .disclaimersWrapper {
    line-height: 1.5;

    display: flex;
    flex-direction: column;
    gap: $spacing-small;
    padding: $spacing-normal;
    border-radius: $radius-extra-small;
    background-color: $card-background;
  }
}
