@import 'src/app/css-variables';

.sectionContainer {
  display: flex;
  flex-direction: column;

  gap: $spacing-normal;

  .sectionTitle {
    text-align: center;
  }
}

.itemsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  gap: $spacing-small;

  &:has(> *:hover) {
    > *:not(:hover) {
      opacity: 0.3;
    }
  }

  @media all and (max-width: $breakpoint-small) {
    grid-template-columns: 1fr;
  }
}
