@import 'src/app/css-variables';

.sectionContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  gap: $spacing-medium;
}

.itemsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  padding-block: $spacing-medium;

  @media all and (max-width: $breakpoint-small) {
    gap: $spacing-medium;
    justify-content: space-around;
  }
}

.itemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-medium;

  padding: 20px $spacing-extra-large;

  @media all and (max-width: $breakpoint-small) {
    gap: $spacing-extra-small;
  }

  @media all and (max-width: $breakpoint-extra-small) {
    flex-direction: column;
  }

  .text {
    color: $primary-color;
    font-weight: bold;
  }
}
