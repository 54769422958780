@import 'src/app/theme/typography';
@import 'src/app/mixins';

.sectionTitle {
  @include section-title-mixin;
}

.sectionSubtitle {
  @include section-subtitle-mixin;
}

.sectionParagraphsWrapper {
  display: flex;
  flex-direction: column;

  gap: $spacing-small;
}

.sectionParagraph {
  @include section-paragraph-mixin;
}
