@import 'src/app/css-variables';

.optionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px;

  color: $text-color;
  background-color: $surface-color;

  &[data-focused='true'] {
    background-color: $highlight-color;
  }

  &[data-selected='true'] {
    color: $text-color-on-primary;
    background-color: $primary-color;
  }
}

.tokenInfoContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  // those 3 could be rendundant for child Copy comp
  & div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
  }

  & > :first-child {
    font-weight: normal;
  }

  > div {
    font-size: 0.85em;
  }
}
