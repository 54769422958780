@import 'src/app/css-variables';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  > div {
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
}

.container {
  flex: 1;
  display: flex;
  overflow: hidden;
  align-items: center;

  padding: 12.5px;
  padding-left: 15px;

  font-size: 0.85em;
  color: $text-color;
  border: 1px solid $border-color;
  background-color: $body-color;

  border-top-left-radius: var(--border-top-left-radius);
  border-top-right-radius: var(--border-top-right-radius);
  border-bottom-left-radius: var(--border-bottom-left-radius);
  border-bottom-right-radius: var(--border-bottom-right-radius);

  &:hover {
    border-color: $highlight-color;
  }

  &[data-is-focused='true'] {
    border-color: $primary-color;
  }

  &[data-is-error='true'] {
    border-color: $error-color;
  }

  input {
    flex: 1;
    padding: 0;
    color: inherit;
    font-size: inherit;
    line-height: 20px;
    font-family: inherit;
    background-color: inherit;
    border: none;

    ::placeholder {
      color: inherit;
      font-weight: normal;
      opacity: 0.5;
      font-size: inherit;
    }

    &:focus {
      outline: none;
    }
  }
}

.input {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.error {
  display: flex;
  padding-left: 15px;

  > div {
    line-height: normal;
    color: $error-color;
  }
}

.extra {
  display: flex;
  height: 100%;
  justify-self: flex-end;
}
