@import 'src/app/css-variables';

.container {
  display: flex;
  justify-content: center;

  --button-border-radius: 50px;
  --button-padding-size: #{$spacing-extra-small};

  --button-text-color: #{$body-color};
  --button-border-color: #{$text-color};
  --button-background-color: #{$text-color};

  --button-padding-vertical: #{$spacing-extra-small};
  --button-padding-horizontal: #{$spacing-medium};

  color: var(--button-text-color);
  padding: var(--button-padding-vertical) var(--button-padding-horizontal);
  border: 1px solid var(--button-border-color);
  border-radius: var(--button-border-radius);
  background-color: var(--button-background-color);

  cursor: pointer;
  text-transform: uppercase;

  @media all and (max-width: $breakpoint-small) {
    --button-padding-vertical: #{$spacing-extra-small};
    --button-padding-horizontal: #{$spacing-medium};
  }

  &[data-type='primary'] {
    --button-background-color: #{$primary-color};
    --button-text-color: #{$text-color-on-primary};
    --button-border-color: #{$primary-color};
  }

  &[data-type='secondary'] {
    --button-background-color: #{$text-color-secondary};
    --button-text-color: #{$text-color-on-secondary};
    --button-border-color: #{$text-color-secondary};
  }

  &[data-mode='outline'] {
    background-color: transparent;
    color: var(--button-background-color);
  }

  &[data-mode='text'] {
    border-color: transparent;
    background-color: transparent;
    color: var(--button-background-color);
    --button-padding-vertical: 0;
    --button-padding-horizontal: 0;
  }

  &[data-disabled='true'] {
    border-color: transparent;
    --button-background-color: #{$button-disabled-color};
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  gap: $spacing-extra-small;
}
