@import 'src/app/css-variables';

$background-size: 190px;

.container {
  flex: 1;
  display: flex;
  isolation: isolate;
  position: relative;
  flex-direction: column;

  overflow: hidden;
  gap: $spacing-normal;
  padding: $spacing-medium;
  border-radius: $radius-small;
  border: 1px solid $border-color;

  &[data-type='personal'] {
    .title,
    .background,
    .link {
      color: $primary-color;
    }
  }

  &[data-type='business'] {
    .title,
    .background,
    .link {
      color: $secondary-color;
    }
  }

  transition: opacity 500ms linear;

  &:hover {
    background: $card-background;
    border-color: $highlight-color;
  }

  .background {
    right: 0;
    bottom: 0;
    z-index: -1;
    position: absolute;

    --multiplier: 1;
    width: calc($background-size * var(--multiplier));
    height: calc($background-size * 374 / 329 * var(--multiplier));

    stroke: none;
    fill: currentColor;

    @media all and (max-width: $breakpoint-medium) {
      --multiplier: 0.8;
    }

    @media all and (max-width: $breakpoint-small) {
      --multiplier: 1;
    }

    @media all and (max-width: $breakpoint-extra-small) {
      --multiplier: 0.8;
    }
  }

  .title {
    font-weight: 900;
    text-transform: uppercase;
  }

  .link {
    font-weight: 600;
    letter-spacing: 1px;
    font-size: $font-size-base;

    &[data-disabled='true'] {
      pointer-events: none;
      color: $disabled-color;
    }
  }

  @media all and (max-width: $breakpoint-small) {
    padding: $spacing-normal;
  }
}

.featuresWrapper {
  display: flex;
  flex-direction: column;

  opacity: 0.8;
  font-weight: 500;

  gap: $spacing-extra-small;
}
